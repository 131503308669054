import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { FADEIN } from 'framers/FadeIn';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import CategoriesList from 'components/CategoriesList';
import { SliderVariant } from 'components/Slider';
import CardListing from 'containers/CardListing';
import Carousel from 'containers/Carousel';
import VideoSection from 'containers/VideoSection';

import sortArr from 'utils/sortArr';
import { ChoiceType, useFilters } from 'hooks/useFilters';

import './ProductListingPage.scss';

const { ARTICLES } = SliderVariant;
const { DOWN } = FADEIN;
const { SINGLE } = ChoiceType;

const ProductsListingPage: FC<ProductsListingPageTypes.ProductsListingProps> = ({
  data: {
    productsListing: {
      urls,
      seo,
      tags,
      banner,
      quizInfo,
      filtersTitle,
      dropdownPlaceholder,
      twoColumnsBanner,
      articleCarousel,
      videoSection,
    },
    allProductDetail: { nodes: products },
    allArticleDetail: { articlesList },
  },
}) => {
  const { title, keywords, description } = seo;
  const {
    selectedCategories,
    sortedItems: sortedProducts,
    allTags,
    handleSelectedCategories,
  } = useFilters(products, 'all', SINGLE);

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} />
      <Container>
        <div className="product-listing-page__quiz">
          <Typography dangerouslySetInnerHTML={quizInfo} size={18} animationVariant={DOWN} />
        </div>
      </Container>
      <CategoriesList
        isH1
        {...{
          filtersTitle,
          dropdownPlaceholder,
          allTags,
          tags,
          selectedCategories,
          handleSelectedCategories,
        }}
      />
      <CardListing cards={sortedProducts} />
      <Banner {...twoColumnsBanner} />
      {articlesList.length && articleCarousel ? (
        <Carousel
          carouselItems={sortArr(articlesList, 'articleCardOrder')}
          carouselVariant={ARTICLES}
          carousel={articleCarousel}
        />
      ) : null}
      <VideoSection {...{ videoSection }} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $articlesUrls: [String]) {
    productsListing(lang: { eq: $lang }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      tags {
        ...TagFragment
      }
      banner {
        ...BannerFragment
      }
      quizInfo
      filtersTitle
      dropdownPlaceholder
      contentElement {
        ...ContentElementFragment
      }
      twoColumnsBanner {
        ...BannerFragment
      }
      articleCarousel {
        ...CarouselFragment
      }
      videoSection {
        ...VideoSectionFragment
      }
    }
    allProductDetail {
      nodes {
        ...ProductCardFragment
      }
    }
    allArticleDetail(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
        articleCardOrder
      }
    }
  }
`;

export default ProductsListingPage;
